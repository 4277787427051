import { useEffect } from 'react';

import { Dedupe as DedupeIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/nextjs';
import { useUser } from 'context/AuthContext';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: 'ch-webcompany-dashboard@' + process.env.npm_package_version,
    tracesSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    integrations: [new DedupeIntegration()],
    ignoreErrors: [],
    normalizeDepth: 5,
  });
}

export const useInitializeSentry = () => {
  const user = useUser();

  useEffect(() => {
    if (user) {
      Sentry.setUser({
        id: user.id,
        roles: user.roles.toJSON().join(','),
        region: user.tenantId,
      });
    }
  }, [user]);
};
