import { useContext, useEffect, useState } from 'react';

import { footerElementId } from 'components/Footer';
import Router from 'next/router';
import Script from 'next/script';

import { fetchApi } from './fetchApi';
import { User } from '../../pages/api/userTypes';
import { AuthContext, useUser } from '../context/AuthContext';

declare global {
  interface Window {
    zE?: any;
    zESettings?: any;
  }
}

const prefillUserDataInWidget = async (userId: string): Promise<void> => {
  const res = await fetchApi(`/api/findUserById?${new URLSearchParams({ userId })}`, {
    method: 'GET',
  });
  if (!res.ok) {
    return;
  }
  const data = (await res.json()) as User;

  window.zE('webWidget', 'prefill', {
    email: {
      value: data.email,
      readOnly: true,
    },
    name: {
      value: `${data.firstName} ${data.lastName}`.trim(),
      readOnly: true,
    },
  });
};

const authenticateWidget = async (tenantId: string, userId: string): Promise<void> => {
  const response = await fetchApi(`/api/zendeskWebWidgetToken`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ tenantId }),
  });

  const jwtZDToken = await response.text();

  window.zESettings = {
    webWidget: {
      authenticate: {
        jwtFn: (callback: Function) => {
          callback(jwtZDToken);
        },
      },
    },
  };

  await prefillUserDataInWidget(userId);
};

function makeSupportButtonSticky() {
  const supportButton = document.getElementById(supportButtonId);
  const footer = document.getElementById(footerElementId);

  if (supportButton && footer) {
    const scrollHeight = document.body.scrollHeight;
    const scrollPosition = window.innerHeight + window.scrollY;

    // Check if the user has scrolled to the end of the page, considering the footer's position
    if (scrollPosition >= scrollHeight - footer.clientHeight) {
      supportButton.style.bottom = `${footer.clientHeight}px`;
    } else {
      supportButton.style.bottom = '0';
    }
  }
}

// This is defined by the Zendesk library
export const supportButtonId = 'launcher';
export const classNameToAdaptSupportButton = 'wait-for-support-button-to-adapt';

export const ZendeskWidget = () => {
  const widgetKey = process.env.NEXT_PUBLIC_ZENDESK_WIDGET_KEY;

  const [isRunning, setRunning] = useState(false);

  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`AuthContext unavailable`);
  }
  const { tenantId, id: userId } = useUser();

  useEffect(() => {
    Router.events.on('routeChangeComplete', () => {
      // For the initial page load on a page change
      setTimeout(makeSupportButtonSticky);
    });

    // When the initial page load shows the footer and then the main content
    // loads, it moves the footer to the bottom (outside the viewport). If this happens,
    // we want to move the button closer to the window bottom limit
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.removedNodes.length > 0) {
          const [removedNode] = mutation.removedNodes;

          if ((removedNode as Element).classList?.contains(classNameToAdaptSupportButton)) {
            setTimeout(makeSupportButtonSticky);
          }
        }
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  }, []);

  useEffect(() => {
    if (isRunning) return;
    setRunning(true);
    authenticateWidget(tenantId, userId).catch((error) => {
      console.error(error);
    });
  }, [tenantId, isRunning, userId]);

  return (
    <>
      <Script
        id="ze-snippet"
        src={'https://static.zdassets.com/ekr/snippet.js?key=' + widgetKey}
        async
        onLoad={() => {
          // The button is loaded into the DOM after a few milliseconds
          const observer = new MutationObserver((mutationsList, observer) => {
            for (const mutation of mutationsList) {
              if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                if ((mutation.addedNodes[0] as any).id === supportButtonId) {
                  // For the initial page load
                  setTimeout(makeSupportButtonSticky);

                  window.addEventListener('scroll', () => {
                    makeSupportButtonSticky();
                  });

                  observer.disconnect();
                }
              }
            }
          });

          observer.observe(document.body, {
            subtree: true,
            childList: true,
          });
        }}
      />

      <style jsx global>{`
        /**
             * Styles for the Zendesk widget. The margin to
             * avoid the overlap with the footer.
             **/
        #launcher {
          margin-bottom: 20px !important;
        }
      `}</style>
    </>
  );
};
