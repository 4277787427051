import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, CssBaseline } from '@coachhubio/ui-components';
import { NotificationProvider } from 'context/NotificationContext';

import { organizationApiClient } from './apollo/clients';
import { theme } from './localTheme';
import { I18nProvider } from './translations/I18nProvider';

const TypeSafeCssBaseline = CssBaseline as React.FC<any>;
const TypeSafeThemeProvider = ThemeProvider as React.FC<any>;

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <I18nProvider>
      <TypeSafeThemeProvider theme={theme}>
        <TypeSafeCssBaseline>
          <ApolloProvider client={organizationApiClient()}>
            <NotificationProvider>{children}</NotificationProvider>
          </ApolloProvider>
        </TypeSafeCssBaseline>
      </TypeSafeThemeProvider>
    </I18nProvider>
  );
}
