import { useEffect, useState } from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useUser } from '../context/AuthContext';

export const useInitializeLaunchDarkly = () => {
  const [initialized, setInitialized] = useState(false);

  const ldClient = useLDClient();
  const { organizationId, id: userId, roles } = useUser();

  useEffect(() => {
    const initializeLaunchDarkly = async () => {
      ldClient
        ?.identify({
          key: process.env.NEXT_PUBLIC_LAUNCHDARKLY_ENV === 'dev' ? 'dev-user' : userId,
          custom: {
            roles: roles.toJSON(),
            organizationId,
          },
        })
        .catch((error) => {
          console.error('Error INIT LD', error);
        })
        .finally(() => setInitialized(true));
    };

    initializeLaunchDarkly();
  }, [userId, ldClient]);

  return { initialized };
};
