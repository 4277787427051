import { useCallback, ReactNode } from 'react';

import { getBorder, getSingleBorder } from '@coachhubio/nova-borders';
import { getAliasColorValue, getColor } from '@coachhubio/nova-colors';
import { Divider } from '@coachhubio/nova-divider';
import { Row } from '@coachhubio/nova-flex';
import { Header, HeaderAvatar, HeaderAvatarDropdownButton } from '@coachhubio/nova-header';
import { getSpacingTokenValue } from '@coachhubio/nova-spaces';
import { Tag } from '@coachhubio/nova-tag';
import { Text } from '@coachhubio/nova-text';
import { makeStyles } from '@coachhubio/ui-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useSWR from 'swr';
import { fetchApi } from 'utils/fetchApi';
import { getBffHost } from 'utils/helper';

import LanguageSelector from './LanguageSelector';
import { Link } from './Link';
import { NominateAction } from './NominateAction';
import ViewAs from './ViewAs';
import { User } from '../../pages/api/userTypes';
import { AuthRole, useAuth, useUser } from '../context/AuthContext';
import { useFlags } from '../hooks/useFlags';

type NavItems = {
  href: string;
  label: ReactNode;
  name: string;
  onClick?: () => void;
  renderer?: (active?: boolean, label?: ReactNode, name?: string, onClick?: () => void) => ReactNode;
}[];

export const headerElementId = 'app-bar-header';

const AppBarContainer = styled.div`
  width: 100%;
  z-index: 10;
  position: sticky;
  top: 0;
`;

const SideDrawer = styled.div`
  z-index: 20;
`;

const StyleDivider = styled(Divider)`
  ${getColor('border-color-subdued')}

  ${getBorder({
    color: 'border-color-subdued',
    width: 's',
    positionRelative: false,
  })}
  
  ${getSingleBorder({
    color: 'border-color-subdued',
    width: 's',
    position: 'start',
    style: 'solid',
  })}
`;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    height: 'auto',
    justifyContent: 'center',
  },
  stickyWrapper: {
    zIndex: 10,
    position: 'sticky',
    top: 0,
  },
  toolbar: {
    maxWidth: 1332,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    padding: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  link: {
    ...theme.styleguide.typography.header,
    whiteSpace: 'nowrap',
    color: getAliasColorValue('text30'),
    fontWeight: 'normal',
    padding: getSpacingTokenValue('s'),
    '&:hover': {
      textDecoration: 'none',
    },
  },
  menuLinks: {
    display: 'flex',
    gap: '60px',
  },
  logoAndSwitchContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    lineHeight: 0,
  },
}));

export function AppBar() {
  const router = useRouter();
  const { roles, id, tenantId } = useUser();
  const { logout } = useAuth();
  const { t } = useTranslation('AppBar');
  const { colorPrimary, ...classes } = useStyles();
  const { enableCoacheeList, enableBudgetPage } = useFlags();

  // Fetch data for logged in user by id
  const { data: user } = useSWR<User>(`/api/findUserById?${new URLSearchParams({ userId: id })}`, (url: string) =>
    fetchApi(url).then(async (r) => r && r.status === 200 && (await r.json()))
  );

  const isBudgetPageEnable = roles.has(AuthRole.OPERATIONS) || (enableBudgetPage && roles.has(AuthRole.CUSTOMER));

  const showOperationsView = roles.has(AuthRole.OPERATIONS);

  const isAdminPage = router.asPath.startsWith('/admin');
  const isCoacheeListPage = router.asPath.startsWith('/coachee-list');

  const getNameInsight = useCallback(() => {
    switch (router.pathname) {
      case '/impact':
      case '/engagement':
      case '/satisfaction':
        return router.pathname;
      default:
        return '/';
    }
  }, [router.pathname]);

  const getNamePrograms = useCallback(() => {
    switch (router.pathname) {
      case '/programs/[...slug]':
        return router.asPath;
      default:
        return '/programs';
    }
  }, [router.pathname, router.asPath]);

  const navMenu: NavItems = [
    ...([
      {
        label: t('insight'),
        name: getNameInsight(),
        renderer: (active, label) => (
          <Link className={classes.link} href="/" data-testid="insights">
            <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
          </Link>
        ),
      },
    ] as NavItems),
    ...(isBudgetPageEnable
      ? ([
          {
            label: t('appBar.budget'),
            name: '/budget',
            renderer: (active, label) => (
              <Link className={classes.link} href="/budget" data-testid="budget">
                <Row gap="s" alignItems="center">
                  <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
                  {!showOperationsView ? <Tag variant="hint">New</Tag> : null}
                </Row>
              </Link>
            ),
          },
        ] as NavItems)
      : []),
    ...([
      {
        label: t('programs'),
        name: getNamePrograms(),
        renderer: (active, label) => (
          <Link className={classes.link} href="/programs" data-testid="programs">
            <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
          </Link>
        ),
      },
    ] as NavItems),
    ...(enableCoacheeList
      ? ([
          {
            label: t('coacheeList'),
            name: '/coachee-list',
            renderer: (active, label) => (
              <Link className={classes.link} href="/coachee-list" data-testid="coacheeList">
                <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
              </Link>
            ),
          },
        ] as NavItems)
      : []),
    ...(!isBudgetPageEnable
      ? ([
          {
            label: t('accounts'),
            name: '/accounts',
            renderer: (active, label) => (
              <Link className={classes.link} href="/accounts" data-testid="accounts">
                <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
              </Link>
            ),
          },
        ] as NavItems)
      : []),
    ...(showOperationsView
      ? ([
          {
            label: t('manage'),
            name: '/admin/company/edit',
            renderer: (active, label) => (
              <Link className={classes.link} href="/admin/[...slug]" as={'/admin/company/edit'} data-testid="admin">
                <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
              </Link>
            ),
          },
        ] as NavItems)
      : []),
    ...([
      {
        label: t('helpCenter'),
        name: '/help-center',
        renderer: (active, label) => (
          <Link
            className={classes.link}
            href={`${getBffHost(tenantId)}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="helpCenter"
          >
            <Text modifier={active ? 'bold' : 'regular'}>{label}</Text>
          </Link>
        ),
      },
    ] as NavItems),
  ];

  return (
    <AppBarContainer id={headerElementId}>
      <Header
        logoIconOnly
        navAlignment="start"
        navMenu={navMenu}
        logoClick={() => router.push('/')}
        activeName={isCoacheeListPage ? '/coachee-list' : isAdminPage ? '/admin/company/edit' : router?.asPath}
      >
        <Row gap="xs">
          <NominateAction />
          {showOperationsView && <ViewAs />}
          {showOperationsView && <StyleDivider marginX="2xs" />}
          <LanguageSelector />
          <HeaderAvatarDropdownButton
            menuItems={[
              {
                items: [
                  ...(isBudgetPageEnable ? [{ label: t('accounts'), onClick: () => router.push('/accounts') }] : []),
                  {
                    label: t('logout'),
                    onClick: logout,
                  },
                ],
              },
            ]}
          >
            <HeaderAvatar firstName={user?.firstName?.toString().at(0)} lastName={user?.lastName?.toString().at(0)} />
          </HeaderAvatarDropdownButton>
        </Row>
      </Header>

      <SideDrawer id="coachee-list-drawer"></SideDrawer>
    </AppBarContainer>
  );
}
