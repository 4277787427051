import React from 'react';

import { marginX, marginY } from '@coachhubio/nova-spaces';
import styled from 'styled-components';

import OrganizationSelect from './OrganizationSelector';
import { AuthContext, useUser } from '../../context/AuthContext';

const SelectorContainer = styled.div`
  ${marginY('none')}
  ${marginX('s')}
`;

export function ViewAs() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`AuthContext unavailable`);
  }

  const { accountId, tenantId } = useUser();

  const handleChange = async ({ accountId, tenantId }: { accountId: string; tenantId: string }) => {
    const result = await fetch('/api/currentUser', {
      method: 'PUT',
      body: JSON.stringify({ accountId, tenantId }),
    });

    const res = await result.json();
    const organizationId = res.organizationId;

    if (result.status === 200) {
      context.setUser({ ...context.user, organizationId, accountId, tenantId });
    }
  };

  return (
    <SelectorContainer>
      <OrganizationSelect
        accountId={accountId}
        tenantId={tenantId}
        limit={30}
        setSelectedAccountOrganization={(org: { accountId: string; tenantId: string }) => {
          org && handleChange(org);
        }}
      />
    </SelectorContainer>
  );
}
